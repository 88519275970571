import {Layout, Row, Space} from "antd";
import {useState} from "react";
import {About, CurrentUserAvatar, ContactUsLink, ContactUsModal, GuideButton,
    HomeLink, LogOut, OtherResources} from "@digatex/digatex-ui-lib";
import RepApi from "../../../services/RepApi";

function sendEmail(formData) {
    return RepApi.post(`/feedback`, formData);
}

export function Header() {
    const [showContactForm, setShowContactForm] = useState(false)

    return (
        <Layout.Header style={{padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <HomeLink/>
            <div style={{marginLeft: 'auto'}}>
                <Row>
                    <Space size={"middle"}>
                        <About/>
                        <OtherResources/>
                        <GuideButton module="reporting"/>
                        <ContactUsLink show={() => setShowContactForm(true)}/>
                        <LogOut />
                        <CurrentUserAvatar/>
                    </Space>
                </Row>
            </div>
            <ContactUsModal show={showContactForm} close={() => setShowContactForm(false)} sendEmail={sendEmail}/>
        </Layout.Header>
    )
}

