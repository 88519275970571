import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import App from './App';
import {createBrowserHistory} from "history";
import {Router} from "react-router-dom";
import {UI_CONFIG} from "./constants";
import {Auth, CookieNotice, UIConfigContext} from "@digatex/digatex-ui-lib"

const history = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById('root'));
Auth.init(() => root.render(
    <UIConfigContext.Provider value={UI_CONFIG}>
        <CookieNotice/>
        <Router history={history}>
            <App/>
        </Router>
    </UIConfigContext.Provider>
));
